import { ExtractAtomicOperationFromDefinition } from "../../atomic-operations/AtomicOperationDefinition.js";
import { DateTimeString } from "../../dateTypes.js";
import { CellRunMode } from "../../enums.js";
import { ReviewRequestId, StatusId } from "../../idTypeBrands.js";
import { RichTextDocument } from "../../richTextTypes.js";
import { SemanticCap } from "../../semanticCaps.js";
import { createHexAtomicOperationDefinition } from "../HexAtomicOperationDefinition.js";

export interface HexUpdatableFields {
  cellRunMode: CellRunMode;
  publishAsSnapshot: boolean;
  archivedDate: DateTimeString | null;
  unarchivedDate: DateTimeString | null;
  currentReviewRequestId: ReviewRequestId | null;
  requiresReview: boolean;
  statusId: StatusId | null;
  title: string;
  description: RichTextDocument | null;
  canPublicViewLogic: boolean;
}

const _UpdateHexSafeFields: {
  [key in keyof HexUpdatableFields]: "";
} = {
  cellRunMode: "",
  publishAsSnapshot: "",
  archivedDate: "",
  unarchivedDate: "",
  currentReviewRequestId: "",
  requiresReview: "",
  statusId: "",
  title: "",
  description: "",
  canPublicViewLogic: "",
};
export const UpdateHexSafeFields = Object.keys(_UpdateHexSafeFields);

const UPDATE_HEX_TYPE = "UPDATE_HEX" as const;
export const UPDATE_HEX = createHexAtomicOperationDefinition({
  type: UPDATE_HEX_TYPE,
  readAuth: {
    kind: "hasSemanticCap",
    cap: SemanticCap.VIEW_PROJECT_CONTENTS_FOR_APP,
  },
  writeAuth: {
    kind: "and",
    and: [
      {
        kind: "hasSemanticCap",
        cap: SemanticCap.VIEW_PROJECT_CONTENTS_FOR_APP,
      },
      {
        kind: "noAuth",
        message:
          "More specific write auth is checked manually in the handler and varies depending on key arg",
      },
    ],
  },
  logSafe: ["key"],
  alwaysSkipUndoBuffer: true,
  conflictId: (op) => `${UPDATE_HEX_TYPE}-${op.payload.key}`,
  excludeFromHistory: (op) => op.payload.key === "currentReviewRequestId",
  create: <K extends keyof HexUpdatableFields>(
    key: K,
    value: HexUpdatableFields[K],
  ) => ({
    type: UPDATE_HEX_TYPE,
    payload: {
      key,
      value,
    },
  }),
});

export type UPDATE_HEX = ExtractAtomicOperationFromDefinition<
  typeof UPDATE_HEX
>;
