import { ExtractAtomicOperationFromDefinition } from "../../atomic-operations/AtomicOperationDefinition.js";
import { DateTimeString } from "../../dateTypes";
import {
  CellId,
  DataConnectionId,
  HexId,
  MagicEventId,
  UserId,
} from "../../idTypeBrands";
import {
  MagicAcceptType,
  MagicCellChainTemplateType,
  MagicCellPayload,
  MagicCellRunResult,
  MagicClientReferenceInfo,
  MagicEventFailureReason,
  MagicEventSource,
  MagicEventStatus,
  MagicKeyword,
  MagicRequestMode,
  MentionedDataframeName,
} from "../../MagicTypes";
import { RichTextDocument } from "../../richTextTypes.js";
import { SemanticCap } from "../../semanticCaps.js";
import { createHexVersionAtomicOperationDefinition } from "../HexVersionAtomicOperationDefinition";

export interface MagicEventPayload {
  id: MagicEventId;
  cellId: CellId | null;
  accepted: boolean | null;
  createdDate: DateTimeString;
  dataConnectionId: DataConnectionId | null;
  eventSource: MagicEventSource | null;
  eventType: MagicKeyword;
  result: string | null;
  status: MagicEventStatus | null;
  userId: UserId;
  userProvidedPrompt: string | null;
  parentEventId: MagicEventId | null;
  unsupportedResponse: boolean;
  prevEventId: MagicEventId | null;
  nextEventId: MagicEventId | null;
  revision: number;
  chainTemplateType: MagicCellChainTemplateType | null;
  requestMode: MagicRequestMode | null;
  cellRunResult: MagicCellRunResult | null;
  acceptType: MagicAcceptType | null;
  userFacingResult: string | null;
  richTextPrompt: RichTextDocument | null;
  mentionedDataframes: MentionedDataframeName[] | null;
  failureReason: MagicEventFailureReason | null;
  autoTriggered: boolean;
  duration: number | null;
  timeToFirstToken: number | null;
  cellPayload: MagicCellPayload | null;
  relatedProjectIds: HexId[] | null;
  topReferenceInfo: MagicClientReferenceInfo[] | null;
  chainSummary: string | null;
}

const CREATE_MAGIC_EVENT_TYPE = "CREATE_MAGIC_EVENT" as const;
export const CREATE_MAGIC_EVENT = createHexVersionAtomicOperationDefinition({
  type: CREATE_MAGIC_EVENT_TYPE,
  readAuth: {
    kind: "hasSemanticCap",
    cap: SemanticCap.VIEW_PROJECT_CONTENTS_FOR_LOGIC,
  },
  writeAuth: {
    kind: "or",
    // If there is not cell already created, defualt to if the user has project
    // edit access
    or: [
      {
        kind: "and",
        and: [
          {
            kind: "hasSemanticCap",
            cap: SemanticCap.EDIT_PROJECT_CONTENTS,
          },
          {
            kind: "idArgDoesNotExist",
            idArg: "cellId",
            idType: "Cell",
          },
        ],
      },
      // If there is a cell created that we're creating a magic event on the
      // user must have access to the underlying cell to be able to create the
      // magic event linked to that cell. This is important for cases like
      // Components where the user might not have access to the underlying cell.
      {
        kind: "and",
        and: [
          {
            kind: "hasSemanticCap",
            cap: SemanticCap.EDIT_PROJECT_CONTENTS,
          },
          {
            kind: "hasSemanticCapOnIdArg",
            cap: SemanticCap.EDIT_PROJECT_CONTENTS,
            idArg: "cellId",
            idType: "Cell",
          },
        ],
      },
      {
        kind: "and",
        and: [
          {
            kind: "hasSemanticCap",
            cap: SemanticCap.EDIT_PROJECT_CONTENTS,
          },
          {
            kind: "argIs",
            arg: "cellId",
            expectedValue: null,
          },
        ],
      },
    ],
  },
  logSafe: [
    "id",
    "cellId",
    "accepted",
    "createdDate",
    "dataConnectionId",
    "eventSource",
    "eventType",
    "status",
    "userId",
    "parentEventId",
    "unsupportedResponse",
    "prevEventId",
    "nextEventId",
    "revision",
    "chainTemplateType",
    "requestMode",
    "cellRunResult",
    "failureReason",
    "autoTriggered",
    "duration",
    "timeToFirstToken",
  ],
  conflictId: (op) => `${CREATE_MAGIC_EVENT_TYPE}-${op.payload.id}`,
  excludeFromHistory: true,
  create: (args: MagicEventPayload) => ({
    type: CREATE_MAGIC_EVENT_TYPE,
    payload: {
      ...args,
    },
  }),
});

export type CREATE_MAGIC_EVENT = ExtractAtomicOperationFromDefinition<
  typeof CREATE_MAGIC_EVENT
>;
