import { HexVersionId } from "./idTypeBrands.js";

/**
 * This takes in an input source and replaces any file download urls with a hex
 * version specific file download url. If there is no match for a file download
 * url in the provided source, then this is a no-op
 */
export const updateFileUrlsInSource = (
  hexVersionId: HexVersionId,
  source: string,
): string => {
  return source.replace(/(?<=\/api\/v1\/file\/)[a-z0-9/-]+/g, (match) => {
    const srcArray = match.split("/");
    const fileId = srcArray.pop();
    return [hexVersionId, fileId].join("/");
  });
};

/**
 * Generate new name for duplicated projects. Prefixes with Copy of, then
 * appends an index, then increments said index. Intended to reduce incidence of
 * repetitive Copy of Copy of Copy of Some Title.
 */
export function generateNewName(title: string): string {
  // Check if title starts with "Copy of"
  if (title.startsWith("Copy of")) {
    // Extract the index from the existing parentheses
    const indexMatch = title.match(/\((\d+)\)$/)!;
    if (indexMatch && indexMatch[1]) {
      // If index found, increment it
      const index = parseInt(indexMatch[1]) + 1;
      return title.replace(/\(\d+\)$/, `(${index})`);
    } else {
      // If no index found, append "(2)"
      return title + " (2)";
    }
  } else {
    // If title doesn't start with "Copy of", append "Copy of" prefix
    return `Copy of ${title}`;
  }
}
