import {
  CellType,
  FilledDynamicValueTableColumnType,
  FilterCellColumnId,
} from "..";

import {
  ColumnOperation,
  Filter,
  FilterCellBinaryColumnPredicate,
  FilterCellBinaryColumnPredicateOp,
  FilterCellColumnPredicateOp,
  FilterCellListBinaryColumnPredicate,
  FilterCellListBinaryColumnPredicateOp,
  FilterCellUnaryColumnPredicate,
  FilterGroup,
} from ".";

// constant map of the column types/operations we want to profile the unique values of
export const COLUMNS_TO_PROFILE: Record<
  FilledDynamicValueTableColumnType,
  Set<FilterCellColumnPredicateOp>
> = {
  STRING: new Set<FilterCellColumnPredicateOp>([
    FilterCellBinaryColumnPredicateOp.EQ,
    FilterCellBinaryColumnPredicateOp.NEQ,
    FilterCellListBinaryColumnPredicateOp.IS_ONE_OF,
    FilterCellListBinaryColumnPredicateOp.NOT_ONE_OF,
  ]),
  DATETIME: new Set<FilterCellColumnPredicateOp>(),
  DATETIMETZ: new Set<FilterCellColumnPredicateOp>(),
  DATE: new Set<FilterCellColumnPredicateOp>([
    FilterCellBinaryColumnPredicateOp.DATE_EQUAL,
  ]),
  TIME: new Set<FilterCellColumnPredicateOp>(),
  NUMBER: new Set<FilterCellColumnPredicateOp>(),
  UNKNOWN: new Set<FilterCellColumnPredicateOp>(),
  BOOLEAN: new Set<FilterCellColumnPredicateOp>(),
};

export function getColumnsToProfile(
  filterGroup: FilterGroup | null,
): FilterCellColumnId[] {
  if (filterGroup == null) {
    return [];
  }
  return filterGroup.filters.flatMap((filterOrGroup) => {
    if (FilterGroup.guard(filterOrGroup)) {
      return getColumnsToProfile(filterOrGroup);
    } else if (Filter.guard(filterOrGroup)) {
      if (filterShouldBeProfiled(filterOrGroup)) {
        return filterOrGroup.column;
      }
      return [];
    }
    return [];
  });
}

export function filterShouldBeProfiled(filter: Filter): boolean {
  return COLUMNS_TO_PROFILE[filter.columnType]?.has(filter.operation.op);
}

// we limit loading the dataframe to 2000 but limit here to 2001 becase we still
// want to see if the value is truncated on load
export const COLUMN_PROFILE_VALUES_LIMIT = 2001;

export function getDistinctColumnValuesSql({
  columnName,
  dataframeName,
}: {
  columnName: string;
  dataframeName: string;
}): string {
  // we limit loading the dataframe to 2000 but limit here to 2001 becase we still
  // want to see if the value is truncated on load
  return `select distinct "${columnName}" from ${dataframeName} limit 2001;`;
}

function getReferencedColumnsInternal(
  filterGroup: FilterGroup | null,
): FilterCellColumnId[] {
  if (filterGroup == null) return [];
  return filterGroup.filters.flatMap((filterOrGroup) => {
    if (FilterGroup.guard(filterOrGroup)) {
      return getReferencedColumnsInternal(filterOrGroup);
    } else if (Filter.guard(filterOrGroup)) {
      return filterOrGroup.column ? [filterOrGroup.column] : [];
    } else {
      return [];
    }
  });
}

export function getReferencedColumns(
  filterGroup: FilterGroup | null,
): FilterCellColumnId[] {
  return Array.from(new Set(getReferencedColumnsInternal(filterGroup)));
}

export function toInternalColumnOperation(
  op: ColumnOperation,
  allowInterpolation: boolean,
): ColumnOperation {
  if (FilterCellUnaryColumnPredicate.guard(op)) {
    return op;
  } else if (
    FilterCellBinaryColumnPredicate.guard(op) ||
    FilterCellListBinaryColumnPredicate.guard(op)
  ) {
    return {
      ...op,
      allowInterpolation,
    };
  }
  return op;
}

export function isFilterableCellType(cellType: CellType): boolean {
  return (
    cellType === "SQL" ||
    cellType === "CHART" ||
    cellType === "DISPLAY_TABLE" ||
    cellType === "PIVOT"
  );
}
